/* eslint-disable indent */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import styled from '@emotion/styled'
import idx from 'idx'
import Scanner from '../form/Scanner'
import Text from '../common/Text'
import Space from '../common/Space'
import Button from '../common/Button'
import getImage from '../../utils/getImage'
import ModalAnimated from '../common/modal/ModalAnimated'
import VideoIframe from '../common/VideoIframe'
import { theme } from '../../styles'

import { useUserLang } from '../../context/UserLangContext'
import generateAriaLabelTranslations from '../../data/generateAriaLabelTranslations'

const BG = styled.div`
  position: absolute;
  ${props => props.hasSwoop && props.hasGradientBG && 'border-radius: 0 0 100% 100%;'}
  ${p =>
    p.hasGradientBG
      ? `
  width: 150vw;
  height: 100%;
  background-size: 125%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-color:hsla(266,94%,20%,1);
  background-image:
  radial-gradient(at 0% 5%, hsla(170,69%,48%,0.47) 0px, transparent 50%),
  radial-gradient(at 98% 39%, hsla(266,80%,49%,0.8) 0px, transparent 50%),
  radial-gradient(at 100% 99%, hsla(170,69%,48%,0.82) 0px, transparent 50%);
  z-index: -1;
  animation: gradient 12s ease-in-out infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 35%;
    }
    50% {
      background-position: 75% 100%;
    }
    100% {
      background-position: 0% 35%;
    }
  }

  @media (max-width: 1050px) {
    width: 275vw;
  }
  `
      : `
  background-color: ${theme.color[p.backgroundColor]};
  width: 500vw;
  margin-left: -50%;
  height: 100%;
  z-index: -1;
`}
`

const BannerWrapper = styled.div`
  display: grid;
  grid-template-columns: ${p => (p.isCentered ? '1fr' : '1fr 1fr')};
  justify-items: center;
  position: relative;
  grid-column-gap: 6%;
  padding: 80px 0px;
  width: 100%;
  transition: height 200ms ease;

  ${p =>
    p.hasGradientBG &&
    `
      padding-top: ${p.hasSignUpForm ? '140px' : '80px'} ;
      padding-bottom: ${p.smallPaddingBottom ? '80px' : '164px'};
  `}

  @media (max-width: 1200px) {
    grid-column-gap: 0;
    grid-row-gap: 64px;
    grid-template-columns: 1fr;
    width: 100%;
    margin-left: 0px;
    padding-top: 80px;
  }

  @media (max-width: 1050px) {
    grid-row-gap: 40px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  margin-top: 24px;

  ${p =>
    p.isCentered &&
    `
    max-width: 750px;
    align-items: center;
    margin: 16px 0px 56px 0px;
    h1, h2, p {
      text-align: center;
    }
  `}

  ${p =>
    p.hasGradientBG &&
    `
    a::not(.button) {
      background-image: linear-gradient(to bottom, ${theme.color.white} 0%, ${theme.color.white} 100%);
      color: ${theme.color.white};
      &:hover {
          color: ${theme.color.purple500}!important;
      }
    }
  `}

  @media (max-width: 1600px) {
    margin-top: 24px;
  }

  @media (max-width: 1200px) {
    margin-top: 0px;
    align-items: center;
    width: 90%;

    .rich-text-container {
      text-align: center;
      margin: 0 auto;
    }
    label p {
      text-align: left;
    }
  }

  @media (max-width: 480px) {
    align-items: start;

    .rich-text-container {
      text-align: left !important;
      margin: 0;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

const RightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1050px) {
    display: ${p => (p.hasVideo ? 'flex' : p.hasSignUpForm ? 'flex' : p.scannerToTheRightSide ? 'flex' : 'none')};
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 1200px) {
    width: 75%;
  }
`

const ValidationImage = styled.img`
  height: 40px;
  margin: 40px 0px 16px 0px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 800px) {
    gap: 24px;
  }
`

const FormWrapper = styled.div`
  border-radius: 16px;
  background-color: ${theme.color.white};
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  padding: 32px 40px;
  width: 100%;
  max-width: 600px;
  min-width: 500px;
  margin: 32px 0px;
  transition: height 200ms ease;
  overflow: hidden;

  label p {
    text-align: left;
  }

  @media (max-width: 1050px) {
    max-width: 500px;
    min-width: unset;
    margin-top: 0px;
  }
`

const TitleText = styled(Text)`
  ${props =>
    props.lang !== 'en' &&
    `
    word-break: break-word;
    hyphens: auto;
  `}

  ${p =>
    p.coloredText &&
    `
    font-size: ${theme.fonts.size.heading1};
    font-family: ${theme.fonts.family.heading1};
    font-weight: ${theme.fonts.weight.heading1};
    line-height: ${theme.fonts.lineHeight.heading1};
    letter-spacing: ${theme.fonts.letterSpacing.heading1};

    span {
      color: ${theme.color.green300};
    }
  `}
`

const RichText = styled(Text)`
  ul,
  ol {
    margin-left: 20px;
  }
`

const VideoModal = styled.a`
  text-align: center;
`

const BannerTitleScan = ({ slice: { primary } }) => {
  const title =
    primary.title.richText?.[0]?.text && primary.colored_text.richText?.[0]?.text ? (
      <h1>
        {primary.title.text} <span>{primary.colored_text.text}</span>
      </h1>
    ) : (
      <PrismicRichText field={primary.title.richText} />
    )
  const coloredText = primary.colored_text.richText?.[0]?.text && primary.colored_text.text
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const validationText = primary.validation_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.validation_text.richText} />
  )
  const smallCapsText = primary.small_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.small_caps_text.richText} />
  )
  const largeCapsText = primary.large_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.large_caps_text.richText} />
  )
  const scannerLabelText = idx(primary, _ => _.scanner_input_label.text)
  const imageSrc = idx(primary, _ => _.image.url)
  const validationImageSrc = idx(primary, _ => _.validation_image.url)
  const buttonText = idx(primary, _ => _.button_text.text)
  const buttonUrl = idx(primary, _ => _.button_link.url)
  const secondaryButtonText = idx(primary, _ => _.secondary_button_text.text)
  const secondaryButtonUrl = idx(primary, _ => _.secondary_button_link.url)
  const showScanner = idx(primary, _ => _.show_scanner)
  const hasSignUpForm = primary?.has_sign_up_form
  const hasGradientBG = idx(primary, _ => _.has_gradient_background)
  const backgroundColor = primary.background_color
  const isCentered = idx(primary, _ => _.is_centered)
  const scannerToTheRightSide = idx(primary, _ => _.scanner_to_the_right_side)
  const smallPaddingBottom = idx(primary, _ => _.small_padding_bottom)
  const hasSwoop = primary?.has_swoop
  const video = primary?.video?.url

  const [open, setOpen] = useState(false)

  const { userLang } = useUserLang()
  const languageData = generateAriaLabelTranslations({ userLang })

  return (
    <BannerWrapper
      smallPaddingBottom={smallPaddingBottom}
      isCentered={isCentered}
      hasGradientBG={hasGradientBG}
      hasSignUpForm={hasSignUpForm}
    >
      <InfoWrapper isCentered={isCentered} hasGradientBG={hasGradientBG}>
        {smallCapsText && (
          <Text font="smallCaps" richText color={hasGradientBG ? 'green500' : 'purple500'}>
            {smallCapsText}
          </Text>
        )}
        {largeCapsText && (
          <Text font="largeCaps" richText color={hasGradientBG ? 'yellow200' : 'purple500'}>
            {largeCapsText}
          </Text>
        )}
        {title && (
          <TitleText
            font="heading1"
            richText
            richTextElementStyles={{ display: 'inline' }}
            style={{ marginBottom: '1.45rem' }}
            color={hasGradientBG ? 'white' : 'offBlack'}
            coloredText={coloredText}
            center={isCentered}
            lang={userLang}
          >
            {title}
          </TitleText>
        )}
        {text && (
          <RichText
            color={hasGradientBG ? 'white' : 'offBlack'}
            richText
            className={hasGradientBG ? 'dark-bg-rich-text' : null}
            style={{ maxWidth: '550px' }}
          >
            {text}
          </RichText>
        )}

        <Space height={16} />
        {showScanner && !scannerToTheRightSide && (
          <FormWrapper>
            <Scanner darkBG={hasGradientBG} labelText={scannerLabelText} />
          </FormWrapper>
        )}
        {!showScanner && (buttonUrl || secondaryButtonUrl) && (
          <ButtonWrapper>
            {buttonText && buttonUrl && <Button buttonType="tertiary reversed" text={buttonText} to={buttonUrl} />}
            {secondaryButtonText && secondaryButtonUrl && (
              <Button
                buttonType="white link CTA"
                text={secondaryButtonText}
                to={secondaryButtonUrl}
                style={{ marginRight: '20px' }}
              />
            )}
          </ButtonWrapper>
        )}
        {validationImageSrc && getImage(primary.validation_image, ValidationImage)}
        {validationText && (
          <Text
            color={hasGradientBG ? 'white' : 'offBlack'}
            richText
            font="smallBodyBold"
            className={hasGradientBG ? 'dark-bg-rich-text' : null}
          >
            {validationText}
          </Text>
        )}
      </InfoWrapper>
      {(imageSrc || hasSignUpForm || (scannerToTheRightSide && showScanner)) && (
        <RightWrapper
          style={{ alignItems: scannerToTheRightSide && showScanner ? 'center' : 'flex-start' }}
          hasSignUpForm={hasSignUpForm}
          scannerToTheRightSide={scannerToTheRightSide}
          hasVideo={video?.length > 0}
        >
          {video ? (
            <VideoModal
              tabIndex="0"
              role="button"
              aria-label={languageData?.playVideo}
              onKeyDown={e => {
                if (e.key === 'Enter') setOpen(true)
              }}
              onClick={() => video && setOpen(true)}
              style={{ width: '100%' }}
            >
              {getImage(primary.image, Image, { hasVideo: video.length > 0 })}
            </VideoModal>
          ) : (
            imageSrc && getImage(primary.image, Image, { loading: 'eager' })
          )}
          {hasSignUpForm && (
            <FormWrapper>
              <Scanner darkBG={hasGradientBG} labelText={scannerLabelText} />
            </FormWrapper>
          )}
          {showScanner && scannerToTheRightSide && (
            <FormWrapper>
              <Scanner darkBG={hasGradientBG} labelText={scannerLabelText} />
            </FormWrapper>
          )}
        </RightWrapper>
      )}
      {video && (
        <ModalAnimated
          active={(video && open) === true}
          onClose={() => video && setOpen(false)}
          ariaLabel={languageData?.videoModal}
        >
          <VideoIframe isModal allowFullScreen videoSrc={video} autoPlay={false} />
        </ModalAnimated>
      )}
      <BG
        className="bg shape"
        hasGradientBG={hasGradientBG}
        backgroundColor={backgroundColor === null ? 'offWhite' : backgroundColor}
        hasSwoop={hasSwoop === null || hasSwoop}
      />
    </BannerWrapper>
  )
}

export default BannerTitleScan

export const query = graphql`
  fragment BannerTitleScan on PrismicPageDataBodyBannerTitleScan {
    ...SliceBase
    id
    primary {
      anchor_id
      show_scanner
      scanner_to_the_right_side
      has_sign_up_form
      has_gradient_background
      background_color
      is_centered
      small_padding_bottom
      button_text {
        ...TextContent
      }
      button_link {
        ...LinkContent
      }
      secondary_button_text {
        ...TextContent
      }
      secondary_button_link {
        ...LinkContent
      }
      validation_text {
        ...TextContent
      }
      validation_image {
        alt
        url
        gatsbyImageData(width: 40)
      }
      title {
        ...TextContent
      }
      colored_text {
        ...TextContent
      }
      text {
        ...TextContent
      }
      small_caps_text {
        ...TextContent
      }
      large_caps_text {
        ...TextContent
      }
      scanner_input_label {
        ...TextContent
      }
      has_swoop
      image {
        alt
        url
        gatsbyImageData(width: 1080)
      }
      video {
        url
      }
    }
  }
`
